import React from "react"
import { useTranslation } from "react-i18next"
import * as styles from "./GMapEmbedStatic.module.scss"

/*

Toistaiseksi placeholder.
Käytetään kun halutaan upottaa Google Maps sivulle

*/

export type Coordinates = {
  lat: number
  lng: number
}

export type GoogleMapMarker = {
  color?: string
  label?: string // Yksi merkki A-Z, 0-9, näkyy jos size on mid+
  size?: "tiny" | "mid" | "small" | "normal"
  icon?: string // url karttamerkkiin esim. "https://tvt-fi.web.app/tvt-mapmarker.png"
  anchor?: string // karttaikonin "keskipiste", esim "topleft" tai "32, 10"
} & Coordinates

export type GMapEmbedStaticProps = {
  center?: Coordinates // numbers (latitude and longitude values) or strings (addresses)
  zoom: number
  markers?: GoogleMapMarker[]
  size?: string
  scale?: 1 | 2
  alt: string
  mapId: string
}

const API_KEY = "AIzaSyDpFx9mieCoAIvzSrQwX1Y-uuIlimX9U3Y" // TODO älä kovakoodaa, lue jostain (siteMetaData?)

function markerParam(m: GoogleMapMarker): string {
  if (m.icon) {
    return encodeURIComponent(
      [`anchor:${m.anchor}`, `icon:${encodeURI(m.icon)}`, `scale:2`, `${m.lat},${m.lng}`].join("|")
    )
  }
  return encodeURIComponent(
    [`size:${m.size || "small"}`, `color:${m.color || "0x115A7E"}`, `label:${m.label}`, `${m.lat},${m.lng}`].join("|")
  )
}

const GMapEmbedStatic = (props: GMapEmbedStaticProps) => {
  const { i18n } = useTranslation()
  const urlParams: [string, string | number][] = [
    ["zoom", props.zoom],
    ["size", props.size ? props.size : "640x320"], // maks koko on 640x640 (kun scale on 2 => tarkoittaa 1280x1280)
    ["language", i18n.language],
    ["scale", props.scale ? props.scale : 2],
    ["key", API_KEY],
    ["map_id", props.mapId],
  ]
  if (props.center && props.center.lng && props.center.lat) {
    urlParams.push(["center", `${props.center.lat},${props.center.lng}`])
  }
  const markers = props.markers && props.markers.map(marker => `markers=${markerParam(marker)}`).join("&")

  const paramStr = urlParams.map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join("&")

  return (
    <figure className={styles.container}>
      <img
        src={`https://maps.googleapis.com/maps/api/staticmap?${paramStr}&${markers}`}
        alt={props.alt}
        loading="lazy"
      />
    </figure>
  )
}

export default GMapEmbedStatic
