import React from "react"
import { useTranslation } from "react-i18next"
import { TampuuriApartmentFieldsFragment } from "../../pages/TampuuriPropertyPage"
import ApartmentGroup from "./ApartmentGroup"

/*

Kiinteistösivuilla asunnot listaava komponentti

*/

export type PropertyApartmentListProps = {
  apartments: {
    // one: readonly (HasuraApartment | undefined)[]
    // two: readonly (HasuraApartment | undefined)[]
    // three: readonly (HasuraApartment | undefined)[]
    // fourplus: readonly (HasuraApartment | undefined)[]
    // other: readonly (HasuraApartment | undefined)[]
    one: TampuuriApartmentFieldsFragment[]
    two: TampuuriApartmentFieldsFragment[]
    three: TampuuriApartmentFieldsFragment[]
    fourplus: TampuuriApartmentFieldsFragment[]
    other: TampuuriApartmentFieldsFragment[]
  }
}

export type AparmentGroups = { [_: string]: TampuuriApartmentFieldsFragment[] }

/**
 * Pyöräytetään asunnot tyyppiryhmiin (tyyppi + pinta-ala).
 * Tämä olisi hyvä tehdä jo välityspalvelimella kunhan päästään sopuun ryhmittelysäännöistä
 *
 * @param aps
 * @param hIds
 */
const groupByType = (aps: readonly (TampuuriApartmentFieldsFragment | undefined)[]): AparmentGroups => {
  if (!aps) {
    return Object.create(null)
  }
  const typeGroups: AparmentGroups = {}
  aps.forEach(ap => {
    if (ap && ap.type_key) {
      const typeGroup = ap.type_key
      if (!typeGroups[typeGroup]) {
        typeGroups[typeGroup] = [ap]
      } else {
        typeGroups[typeGroup] = typeGroups[typeGroup].concat([ap])
      }
    }
  })
  return typeGroups
}

const PropertyApartmentList = (props: PropertyApartmentListProps) => {
  // console.debug("Rendering PropertyApartmentList", props)
  const { t } = useTranslation("asuntosivu")
  const { apartments } = props

  if (!apartments) {
    return null
  }

  return (
    <div>
      {apartments.one && <ApartmentGroup title={t("Yksiöt", "Yksiöt")} groups={groupByType(apartments.one)} />}
      {apartments.two && <ApartmentGroup title={t("Kaksiot", "Kaksiot")} groups={groupByType(apartments.two)} />}
      {apartments.three && <ApartmentGroup title={t("Kolmiot", "Kolmiot")} groups={groupByType(apartments.three)} />}
      {apartments.fourplus && (
        <ApartmentGroup
          title={t("Neliöt ja suuremmat", "Neliöt ja suuremmat")}
          groups={groupByType(apartments.fourplus)}
        />
      )}
      {apartments.other && <ApartmentGroup title={t("Muut", "Muut")} groups={groupByType(apartments.other)} />}
    </div>
  )
}

export default PropertyApartmentList
