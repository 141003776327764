import { graphql } from "gatsby"
import React from "react"
import DatoCard, { DatoCardFragment } from "../molecules/cards/DatoCard"
// import * as styles from "./PropertyExtraCardsSection.module.scss"

type PropertyExtraCardsSectionProps = PropertyExtraCardsFragment

const PropertyExtraCardsSection = (props: PropertyExtraCardsSectionProps) => {
  // console.debug("Rendering PropertyExtraCardsSection", props)
  const { cards } = props
  if (!cards || cards.length < 1) {
    return null
  }
  return (
    <section>
      {cards.map(card => {
        return <DatoCard key={card.id} {...card} />
      })}
    </section>
  )
}

export default PropertyExtraCardsSection

export type PropertyExtraCardsFragment = {
  cards: DatoCardFragment[]
}

export const query = graphql`
  fragment PropertyExtraCards on DatoCmsPropertyExtraContent {
    cards {
      ...DatoCard
    }
  }
`
