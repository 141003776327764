import React from "react"
import TalotyyppiIcon from "../../../images/icons/pori/kerrostalo.svg"
import RentalIcon from "../../../images/icons/pori/vuokra-asunnot.svg"
import StudentIcon from "../../../images/icons/pori/opiskelija-asunto.svg"
import KaupunginosaIcon from "../../../images/icons/pori/asunto-kartalla.svg"
import RakennusvuosiIcon from "../../../images/icons/pori/rakennusvuosi.svg"
// import HissitaloIcon from "../../../images/icons/pori/hissitalo.svg"
import HuoneistojaIcon from "../../../images/icons/pori/asunnon-pohja.svg"
// import KerroksiaIcon from "../../../images/icons/pori/portaat-ei-hissia.svg"
import * as styles from "./PropertyDetailList.module.scss"
import { useTranslation } from "react-i18next"

type IconDetailProps = {
  label: string
  value: string | null
  icon: string | React.FC<React.SVGAttributes<SVGElement>>
}

const IconsDetail = (props: IconDetailProps) => {
  if (props.value === null) {
    return null
  }
  return (
    <>
      <img src={props.icon as string} alt={`${props.label}`} height="60px" />
      <div>
        <span>{props.label}</span>
        <br />
        <strong>{props.value}</strong>
      </div>
    </>
  )
}

type PropertyDetailListProps = {
  params: HasuraPropertyParameters
  hood?: string
  housingType?: string
}

const PropertyDetailList = (props: PropertyDetailListProps) => {
  // console.debug("Rendering PropertyDetailList", props)
  const { t } = useTranslation("asuntosivu")
  const { hood, params } = props

  const type = props.housingType
  let TypeIcon
  if (type === "rental") {
    TypeIcon = RentalIcon
  } else if (type === "student") {
    TypeIcon = StudentIcon
  }

  return (
    <ul className={styles.list}>
      {type && TypeIcon && (
        <li>
          <IconsDetail label={t("Asumismuoto", "Asumismuoto")} value={t(type, type)} icon={TypeIcon} />
        </li>
      )}
      {params.talotyyppi && (
        <li>
          <IconsDetail
            label={t("Talotyyppi", "Talotyyppi")}
            value={t(params.talotyyppi, params.talotyyppi)}
            icon={TalotyyppiIcon}
          />
        </li>
      )}
      {hood && (
        <li>
          <IconsDetail label={t("Kaupunginosa", "Kaupunginosa")} value={hood} icon={KaupunginosaIcon} />
        </li>
      )}
      <li>
        <IconsDetail
          label={t("Rakennusvuosi", "Rakennusvuosi")}
          value={params.valmistumisvuosi}
          icon={RakennusvuosiIcon}
        />
      </li>
      <li>
        <IconsDetail label={t("Huoneistoja", "Huoneistoja")} value={params.huoneistoja} icon={HuoneistojaIcon} />
      </li>
      {/* <li>
        <IconsDetail label={t("Hissitalo", "Hissitalo")} value="" icon={HissitaloIcon} />
      </li>
      <li>
        <IconsDetail label={t("Kerroksia", "Kerroksia")} value="" icon={KerroksiaIcon} />
      </li> */}
    </ul>
  )
}

export default PropertyDetailList
