import React from "react"
import { graphql } from "gatsby"
import PropertyApartmentList from "../organisms/propertyPage/PropertyApartmentList"
import CarouselHero, { TampuuriHeroImageFragment } from "../organisms/carouselHero/CarouselHero"
import PropertyDetailList from "../organisms/propertyPage/PropertyDetailList"
import { useTranslation } from "react-i18next"
import PropertyGeneralSection from "../organisms/propertyPage/PropertyGeneralSection"
import GMapEmbedStatic from "../organisms/GMapEmbedStatic"
import { Button } from "../atoms/button/Button"
import Markdown, { MarkdownRemarkFragment } from "../dato/markdown/Markdown"
import PropertyExtraCardsSection, { PropertyExtraCardsFragment } from "../dato/PropertyExtraCardsSection"

export type TampuuriPropertyPageProps = {
  property: TampuuriPropertyPageFragment
  extra: DatoPropertyExtraContentFragment
}

const TampuuriPropertyPage = (props: TampuuriPropertyPageProps) => {
  // console.debug("Rendering template TampuuriPropertyPage", props)
  const { t } = useTranslation("asuntosivu")
  const { property, extra } = props

  const p = property.parameters

  // pilkkueroteltu lista rakennusten kohderyhmistä (rental/student/senior)
  const housingType = property.housing_types ? property.housing_types.split(",")[0] : undefined

  const apartments = {
    one: property.oneRoom,
    two: property.twoRoom,
    three: property.threeRoom,
    fourplus: property.fourRoom,
    other: property.otherRoom,
  }

  const [lat, lng] = property?.location?.coordinates ? property.location.coordinates : [null, null]
  const address = `${property.street_address}, ${property.zip_code} ${p.postitoimipaikka || ""}`

  const title = extra ? extra.propertyName : `${property.street_address}, ${property.zip_code} ${p.postitoimipaikka}`

  let markkinointiteksti
  if (extra && extra.marketingTextNode) {
    markkinointiteksti = <Markdown htmlAst={extra.marketingTextNode.childMarkdownRemark.htmlAst} />
  } else if (p.markkinointiteksti) {
    markkinointiteksti = <p>{p.markkinointiteksti}</p>
  }

  return (
    <>
      <h1>{title}</h1>
      <CarouselHero media={property.media} address={property.street_address || ""} />
      <div className="ingress">{markkinointiteksti}</div>

      <section>
        <PropertyDetailList params={p} hood={property.hood} housingType={housingType} />

        <PropertyGeneralSection
          rent_min={property.rent_min}
          rent_max={property.rent_max}
          /* erikoiskäsittely yhdelle kiinteistölle: https://nitrogroup.atlassian.net/browse/PORI-101 */
          showRent={property.id !== 1603298}
          housingType={housingType}
          parameters={p}
          extraInfo={extra ? extra.propertyInfo : undefined}
        />
      </section>
      {extra && <PropertyExtraCardsSection cards={extra.cards} />}
      <section>
        <h2>{t("Asunnot", "Asunnot")}</h2>
        <PropertyApartmentList apartments={apartments} />
      </section>
      {lat && lng && (
        <section>
          <div style={{ display: "flex", flexFlow: "row wrap", justifyContent: "space-between" }}>
            <h2>{t("SijaintiKartalla", "Sijainti kartalla")}</h2>
            <span>
              <a
                href={`https://www.google.com/maps/search/?api=1&map_action=map&query=${address}&zoom=14`}
                target="_blank"
                rel="noopener noreferrer">
                <Button label={t("Avaa kartta", "Avaa kartta")} variant="primary" displayArrow />
              </a>
            </span>
          </div>
          <GMapEmbedStatic
            markers={[{ lat, lng, icon: "https://www.porinyhasunnot.fi/map-pin-logo.png", anchor: "bottom" }]}
            zoom={14}
            alt={`Kartta, ${property.street_address}`}
            mapId="d67ced52566519f0"
          />
        </section>
      )}
    </>
  )
}

export default TampuuriPropertyPage

export interface TampuuriApartmentFieldsFragment {
  id: number
  parameters: HasuraApartmentParameters
  // is_free: boolean
  is_vacant: boolean
  is_hidden: boolean
  is_faux_hidden: boolean
  hide_status: boolean
  type_category: HasuraApartmentTypeCategory
  type_key: string
  rent: number
  size: number
  floorplans: {
    url: string
    format: string
  }[]
  images: {
    url: string
  }[]
}
export interface TampuuriPropertyPageFragment {
  id: number
  location: HasuraLocation
  street_address: string
  zip_code: string
  hood: string
  parameters: HasuraPropertyParameters
  free_apartments: number
  rent_min: number
  rent_max: number
  housing_types: HasuraHousingType
  media: TampuuriHeroImageFragment[]
  buildings: {
    id: number
    name: string
    parameters: HasuraBuildingParameters
  }[]
  oneRoom: TampuuriApartmentFieldsFragment[]
  twoRoom: TampuuriApartmentFieldsFragment[]
  threeRoom: TampuuriApartmentFieldsFragment[]
  fourRoom: TampuuriApartmentFieldsFragment[]
  otherRoom: TampuuriApartmentFieldsFragment[]
}

export type DatoPropertyExtraContentFragment = {
  id: string
  propertyName: string
  propertyInfo: string // JSON muodossa mutta string tullessa
  marketingTextNode: MarkdownRemarkFragment
} & PropertyExtraCardsFragment

export const query = graphql`
  fragment TampuuriApartmentFields on Tampuuri_apartment {
    id
    # name
    parameters
    # is_free
    is_vacant
    is_hidden
    is_faux_hidden
    hide_status
    type_category
    type_key
    rent
    size
    floorplans: media(limit: 1, where: { category: { _eq: floorplan } }) {
      url
      format
    }
    images: media(where: { category: { _eq: marketing } }) {
      url
    }
  }

  fragment TampuuriPropertyPage on Tampuuri_property {
    id
    location
    street_address
    zip_code
    hood
    parameters
    free_apartments
    rent_min
    rent_max
    housing_types
    media(order_by: { position: asc, size: desc }, where: { category: { _in: [facade, marketing] } }) {
      ...TampuuriHeroImage
    }
    buildings {
      id
      name
      parameters
    }
    oneRoom: apartments(where: { type_category: { _eq: one } }) {
      ...TampuuriApartmentFields
    }
    twoRoom: apartments(where: { type_category: { _eq: two } }) {
      ...TampuuriApartmentFields
    }
    threeRoom: apartments(where: { type_category: { _eq: three } }) {
      ...TampuuriApartmentFields
    }
    fourRoom: apartments(where: { type_category: { _eq: fourplus } }) {
      ...TampuuriApartmentFields
    }
    otherRoom: apartments(where: { type_category: { _eq: other } }) {
      ...TampuuriApartmentFields
    }
  }

  fragment DatoPropertyExtraContent on DatoCmsPropertyExtraContent {
    id
    propertyName
    propertyInfo
    marketingTextNode {
      ...MarkdownRemark
    }
    ...PropertyExtraCards
  }
`
