import { Link } from "gatsby"
import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import AsukanneuvontaIcon from "../../../images/icons/pori/asukasneuvoja.svg"
import HakemusIcon from "../../../images/icons/pori/tayta-hakemus.svg"
import IconCard from "../../molecules/cards/IconCard"
import * as styles from "./PropertyGeneralSection.module.scss"

type PropertyGeneralSectionProps = {
  rent_min?: string | number
  rent_max?: string | number
  showRent?: boolean // voidaan poistaa jossain vaiheessa https://nitrogroup.atlassian.net/browse/PORI-101
  parameters: HasuraPropertyParameters & { varustelista?: string }
  housingType?: string
  extraInfo?: string
}

const PropertyGeneralSection = (props: PropertyGeneralSectionProps) => {
  // console.debug("Rendering PropertyGeneralSection", props)
  const { t } = useTranslation("asuntosivu")
  const p = props.parameters

  let tampuuriUrl = t("asuntoHakemusTampuuriUrl", "https://hakemus.tampuuri.fi/porinyhasunnot/")
  if (props.housingType === "student") {
    tampuuriUrl = `${tampuuriUrl}?hakemustyyppi=7`
  }

  const extraTerms = []
  if (props.extraInfo) {
    let extra
    try {
      extra = JSON.parse(props.extraInfo)
    } catch (e) {
      // no op
    }
    if (extra) {
      let i = 0
      for (const [name, value] of Object.entries<string>(extra)) {
        if (name && value) {
          extraTerms.push(
            <Fragment key={`extra-${i++}`}>
              <dt>{name}</dt>
              <dd>{value}</dd>
            </Fragment>
          )
        }
      }
    }
  }

  // poista jossain vaiheessa https://nitrogroup.atlassian.net/browse/PORI-101
  const { showRent = true } = props

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h2>{t("Yleiset tiedot", "Yleiset tiedot")}</h2>
        <dl>
          {showRent && (
            <>
              <dt>{t("Vuokrat", "Vuokrat")}</dt>
              <dd>
                {props.rent_min} - {props.rent_max} €/kk
              </dd>
            </>
          )}
          <dt>{t("Vesimaksu", "Vesimaksu")}</dt>
          <dd>
            {p.vuokraanSisaltyyVesi === "1"
              ? t("Sisältyy vuokraan", "Sisältyy vuokraan")
              : t("Ei sisälly vuokraan", "Ei sisälly vuokraan")}
          </dd>
          {p.lammitysmuoto && (
            <>
              <dt>{t("Lämmitys", "Lämmitys")}</dt>
              <dd>{p.lammitysmuoto}</dd>
            </>
          )}
          {p.varustelista && (
            <>
              <dt>{t("Yhteiset tilat", "Yhteiset tilat")}</dt>
              <dd>{p.varustelista.split(",").join(", ")}</dd>
            </>
          )}
          {extraTerms}
        </dl>
      </div>
      <ul className={styles.links}>
        <li>
          <a href={tampuuriUrl} target="_blank" rel="noopener noreferrer">
            <IconCard
              icon={<img src={HakemusIcon as string} alt="" />}
              label={t("Täytä asuntohakemus", "Täytä asuntohakemus")}
            />
          </a>
        </li>
        <li>
          <Link to={t("yhteystiedotUrl", "/fi/yhteystiedot")}>
            <IconCard
              icon={<img src={AsukanneuvontaIcon as string} alt="" />}
              label={t("Ota yhteyttä", "Ota yhteyttä")}
            />
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default PropertyGeneralSection
