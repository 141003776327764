import { graphql, PageProps } from "gatsby"
import { HelmetDatoCmsTags } from "gatsby-source-datocms"
import React from "react"
import DefaultLayout from "../components/layouts/DefaultLayout"
import { TampuuriHeroImageFragment } from "../components/organisms/carouselHero/CarouselHero"
import { TampuuriHeroMedia } from "../components/organisms/carouselHero/CarouselHeroMedia"
import TampuuriPropertyPage, {
  DatoPropertyExtraContentFragment,
  TampuuriPropertyPageFragment,
} from "../components/pages/TampuuriPropertyPage"

export type TampuuriPropertyQueryProps = {
  id: string
}

export type TampuuriPropertyDataProps = {
  tampuuri: {
    property_by_pk: TampuuriPropertyPageFragment
  }
  extra: DatoPropertyExtraContentFragment
  mainNavData: Queries.DatoMainNavTreeFragment
}

const ytReg = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|video\/|watch\?v=)([^#&?]*).*/
const parseYoutubeUrl = (url: string): string | undefined => {
  const match = url.match(ytReg)
  return match ? match[1] : undefined
}

const TampuuriProperty = (props: PageProps<TampuuriPropertyDataProps>) => {
  // console.debug("Rendering Gatsby Template TampuuriProperty", props)
  const property = props.data.tampuuri.property_by_pk

  function immutableSplice<T>(arr: T[], start: number, deleteCount: number, ...items: T[]) {
    return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)]
  }

  // injektoi mahdollinen esittelyvideo herokaruselliin päätyvään medialistaan
  let allMedia = [...property.media]
  if (property.parameters.esittelyVideoUrl) {
    const ytId = parseYoutubeUrl(property.parameters.esittelyVideoUrl)
    if (ytId) {
      // kukin media herokarusellissa tarvitsee yksilöllisen id arvon kuvien vaihtologiikkaa varten
      allMedia = immutableSplice<TampuuriHeroImageFragment | TampuuriHeroMedia>(allMedia, 1, 0, {
        id: "video",
        youtubeId: ytId,
        alt: "Youtube video",
      })
    }
  }

  property.media = allMedia

  // TODO: Mistä SEO tagit kiinteistösivuille?
  const seoMetaTags: HelmetDatoCmsTags = {
    tags: [
      {
        tagName: "title",
        content: `${property.street_address}, ${property.parameters.postitoimipaikka} - Porin YH-Asunnot Oy`,
      },
      {
        tagName: "description",
        content: property.parameters.markkinointiteksti || "",
      },
    ],
  }
  const { mainNavData } = props.data

  return (
    <DefaultLayout mainNav={mainNavData} seoMetaTags={seoMetaTags}>
      <TampuuriPropertyPage property={property} extra={props.data.extra} />
    </DefaultLayout>
  )
}

export default TampuuriProperty

export const query = graphql`
  query TampuuriProperty($kohdeId: Int!, $locale: String!) {
    tampuuri {
      property_by_pk(id: $kohdeId) {
        ...TampuuriPropertyPage
      }
    }
    extra: datoCmsPropertyExtraContent(propertyId: { eq: $kohdeId }, locale: { eq: $locale }) {
      ...DatoPropertyExtraContent
    }
    mainNavData: datoCmsRoute(slug: { eq: $locale }) {
      ...DatoMainNavTree
    }
  }
`
