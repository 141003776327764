import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import Accordion from "../../molecules/accordion/Accordion"
import { TampuuriApartmentFieldsFragment } from "../../pages/TampuuriPropertyPage"
import * as styles from "./ApartmentGroup.module.scss"
import { AparmentGroups } from "./PropertyApartmentList"

export type ApartmentGroupProps = {
  title: string
  groups: AparmentGroups
}

const ApartmentGroup = (props: ApartmentGroupProps) => {
  // console.debug("Rendering ApartmentGroup", props)
  const { t } = useTranslation("asuntosivu")

  const { groups } = props

  if (Object.keys(groups).length < 1) {
    return null
  }

  // näytetäänkö asunnosta vain minimaaliset tiedot (=se on olemassa)
  function isShown(apartment: TampuuriApartmentFieldsFragment): boolean {
    return (
      apartment.hide_status === false &&
      !(apartment.is_hidden || apartment.is_faux_hidden) &&
      apartment.is_vacant === true
    )
  }

  const rows = Object.keys(groups).map(group => {
    const apartments = groups[group]
    const protoApartment = apartments[0]
    if (!protoApartment) {
      return null
    }

    const freeCount = apartments.filter(h => h.is_vacant === true && isShown(h)).length
    const disabled = freeCount < 1

    const title = (
      <h4 className={styles.title}>
        <span>{protoApartment.parameters.huoneistotyyppi}</span>
        <span>
          {protoApartment.size} m<sup>2</sup>
        </span>
        <span>
          {apartments.length} {t("kpl", "kpl")}
        </span>
        <span>
          {t("vapaana", "vapaana")} {freeCount}
        </span>
      </h4>
    )

    return (
      <Accordion title={title} key={protoApartment.type_key} disabled={disabled} open={true}>
        <table className={styles.details}>
          {/* {freeCount > 0 && (
            <thead>
              <th>Asunto</th>
              <th>Vuokra</th>
              <th>Tilanne</th>
              <th>Pohjapiirros</th>
            </thead>
          )} */}
          <tbody>
            {apartments.map(apartment => {
              if (!isShown(apartment)) {
                return null
              }
              const params = apartment.parameters
              const floorplan = apartment.floorplans && apartment.floorplans.length ? apartment.floorplans[0] : null
              let floorplanUrl = null
              if (floorplan) {
                floorplanUrl = `${apartment.floorplans[0].url}?format=auto`
                // FIXME väliaikainen korjaus Tampuurista siirtyviin, jollain tavalla viallisiin jpeg kuviin. Heikentää pohjakuvan laatua, korjaa siirto Tampuurista välityspalveluun
                if (floorplan.format === "jpg") {
                  floorplanUrl = `${floorplanUrl}&format=jpg`
                }
              }

              return (
                <tr key={apartment.id} id={`apartment-${apartment.id}`}>
                  <td>{params.nimi}</td>
                  {/* {params.kerros ? <td>Kerros {params.kerros}</td> : <td></td>} */}
                  <td>{apartment.rent ? `${apartment.rent} €/kk` : ""} </td>
                  <td>{t(params.huoneistoStatus, params.huoneistoStatus)}</td>
                  <td>
                    {floorplanUrl ? (
                      <a href={floorplanUrl} target="_blank" rel="noopener noreferrer">
                        {t("Pohjapiirros", "Pohjapiirros")}
                      </a>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {apartment.images && apartment.images.length ? (
                      <Link to={`/fi/asuntokuvat/${apartment.id}`}>{t("Kuvat", "Kuvat")}</Link>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Accordion>
    )
  })

  return (
    <>
      <h3>{props.title}</h3>
      {rows}
    </>
  )
}

export default ApartmentGroup
