import React, { ReactNode } from "react"
import * as styles from "./Accordion.module.scss"

type AccordionProps = {
  title: JSX.Element | string
  disabled?: boolean
  open?: boolean
  children?: ReactNode
}

const Accordion = (props: AccordionProps) => {
  const [open, setOpen] = React.useState(props.open === true)

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (props.disabled) {
      return true
    }
    e.preventDefault()
    setOpen(!open)
  }
  const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // console.debug("Accordion onKeyPress", e.key)
    if (props.disabled) {
      return true
    }
    if (e.key === "Enter") {
      setOpen(!open)
    } /* else if (e.key === " ") {
      setOpen(!open)
      return false // estä normaali toiminto eli pagedown scroll
    }*/
  }

  return (
    <div className={`${styles.container} ${props.disabled ? styles.disabled : ""}`} aria-pressed={open}>
      <div
        className={styles.title}
        onClick={onClick}
        onKeyPress={onKeyPress}
        role="button"
        tabIndex={props.disabled ? -1 : 0}>
        <span className={styles.titleText}>{props.title}</span>
        <span className={styles.openIndicator} aria-label={open ? "Sulje" : "Lue lisää"} />
      </div>
      <div className={styles.content} aria-hidden={!open}>
        {props.children}
      </div>
    </div>
  )
}

export default Accordion
