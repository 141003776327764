import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { useTranslation } from "react-i18next"
import Markdown, { MarkdownRemarkFragment } from "../../dato/markdown/Markdown"
import EmbedIframe from "../../organisms/EmbedIframe"
import * as styles from "./DatoCard.module.scss"

type DatoCardProps = DatoCardFragment

const DatoCard = (props: DatoCardProps) => {
  const { i18n } = useTranslation()
  const { htmlAst } = props.paragraphNode.childMarkdownRemark
  let media
  if (props.image && props.image.gatsbyImageData) {
    media = <GatsbyImage style={{ height: "100%" }} alt={props.image.alt || ""} image={props.image.gatsbyImageData} />
  } else if (props.embed) {
    switch (props.embed.__typename) {
      case "DatoCmsYoutubeVideo":
        media = (
          <div className={styles.frame}>
            <EmbedIframe
              title={props.embed.title}
              src={`${props.embed.embedUrl}?rel=0&hl=${i18n.language}&modestbranding=1`}
            />
          </div>
        )
        break
      case "DatoCmsIframeEmbed":
        media = (
          <div className={styles.frame}>
            <EmbedIframe title={props.embed.title} src={props.embed.url} />
          </div>
        )
        break
      default:
        break
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h3 className={styles.title}>{props.title}</h3>
        <div className={styles.paragraph}>{htmlAst && <Markdown headingShift={2} htmlAst={htmlAst} />}</div>
      </div>
      <div className={styles.media}>{media}</div>
    </div>
  )
}

export default DatoCard

type DatoCmsYoutubeVideo = {
  __typename: "DatoCmsYoutubeVideo"
  id: string
  title: string
  embedUrl: string
}

type DatoCmsIframeEmbed = {
  __typename: "DatoCmsIframeEmbed"
  id: string
  title: string
  url: string
}

export type DatoCardFragment = {
  id: string
  title: string
  paragraphNode: MarkdownRemarkFragment
  embed?: DatoCmsYoutubeVideo | DatoCmsIframeEmbed
  image?: {
    alt?: string
    gatsbyImageData?: IGatsbyImageData
  }
}

export const query = graphql`
  fragment DatoCard on DatoCmsCard {
    id
    title
    paragraphNode {
      ...MarkdownRemark
    }
    embed {
      __typename
      ... on DatoCmsYoutubeVideo {
        id
        title
        embedUrl
      }
      ... on DatoCmsIframeEmbed {
        id
        title
        url
      }
    }
    image {
      alt
      # fluid(imgixParams: { fit: "crop", w: "1000", h: "800" }) {
      #  ...GatsbyDatoCmsFluid
      # }
      gatsbyImageData(layout: CONSTRAINED, width: 1000, height: 800)
    }
  }
`
